.social-heading {
	margin-top: 1em;
	margin-bottom: 0.5em;
}
.social-container {
	margin-bottom: 2em;
	display: flex;
	justify-content: center;
	align-items: center;
	.i {
		font-size: 1.5em;
		margin: 0 0.5em;
		color: #571f4e;
	}
}