//--ignore-compile

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Playfair Display", serif;
}

button {
  transition: 250ms ease-in-out;
}

button:hover {
  background-color: #eee;
}