//--ignore-compile

.info {
	padding: 1em;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-radius: 0 0 1em 1em;
	box-shadow: 0 0.1em 0.1em 0 #eee;
	background-color: #fff;
	cursor: pointer;

	p {
		margin-left: 1em;
		color: #571f4e;
	}
	.plus-icon {
		color: #000;
	}
}

.major-heading {
	margin-left: 1em;
	padding-top: 1em;
	padding-bottom: 0.5em;
	font-size: 3em;
	color: rgba(0, 0, 0, 0.4);

	@media (min-width: 500px) and (max-width: 1200px) {
		margin-left: 2em;
	}

	@media (min-width: 1250px) and (max-width: 3000px) {
		margin-left: 1.5em;
	}
}

.about-me {
	margin-left: 2em;

	@media (min-width: 1250px) and (max-width: 3000px) {
		margin-left: 1.5em;
	}
}

.enterprise {

	h2 {
		margin-top: 50%;

		@media (min-width: 1250px) and (max-width: 1500px) {
			margin-top: 10%;
		}
		@media (min-width: 1250px) and (max-width: 3000px) {
			margin-top: 25%;
		}
	}

	.title {
		font-size: 1.25em;
		position: fixed;
		transform: rotate(90deg) translateX(-50%);
		left: -5%;
		top: 50%;
		z-index: -1;

		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			background: #571f4e;
			top: 50%;
			left: -100%;
		}

		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			background: #571f4e;
			top: 50%;
			right: -100%;
		}

		@media (min-width: 500px) and (max-width: 1200px) {
			left: -1.5%;
		}

		@media (min-width: 1250px) and (max-width: 3000px) {
			left: -1%;
		}
	}
}

.me {
	opacity: 0;
	margin-left: 25%;
	box-shadow: 0 0.1em 0.25em #eee;
	border-radius: 0.75em;
	max-width: 75%;

	@media (min-width: 1250px) and (max-width: 3000px) {
		position: absolute;
		right: 0;
		top: 15%;
		max-width: 40%;
	}
}

.line-height {
	margin: 1em 0;
	margin-left: 15vw;
	line-height: 2em;
	opacity: 0;
}

.publications {
	margin: 2em 1.7vw 0 1.7vw;
	text-align: right;
	color: rgba(0, 0, 0, 0.6);

	@media (min-width: 1250px) and (max-width: 3000px) {
		text-align: left;
		max-width: 50%;

		h3 {
			margin-left: 7.5%;
		}

		p {
			margin-left: 7.5%;
		}
	}
}

.skills {
	margin-left: 15%;

	ul {
		margin-left: 2em;
	}

	p {
		padding: 1em;
		margin-left: -1em;
	}

	.social {
		display: flex;
		justify-content: space-between;
		width: 2.25em;
		color: #571f4e;
		margin-top: -1em;
	}

	@media (min-width: 1250px) and (max-width: 3000px) {
		margin-left: 7.5%;

		h3 {
			margin-left: -1em;
		}

		ul {
			margin-left: 0;
		}

		p {
			padding: 0;
			padding-right: 10%;
			margin-top: 1em;
		}

		.social {
			margin-left: -1em;
		}
	}
}

.experience {
	text-align: right;
	z-index: 900;

	h3 {
		margin-top: 1em;
		margin-right: 2.5%;
	}

	.ml {
		margin-right: 2.5%;
		margin-left: 15%;
	}

	.bg-white {
		background-color: #fff;
	}

	@media (min-width: 1250px) and (max-width: 3000px) {
		text-align: left;

		h3 {
			margin-left: 5.5%;
		}

		.ml {
			margin-left: 5.5%;
			padding-right: 10%;
		}
	}
}

a {
	color: #571f4e;
}