.tag-list {
	position: fixed;
	top: 25%;
	right: 25%;
	width: 50%;
	height: 50%;
	border-radius: 1em;
	box-shadow: 0 0.1em 0.25em 0 #eee;
	z-index: 800;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 1em;
	background-color: #fff;

	.close {
		position: absolute;
		top: 2.5%;
		left: 5%;
		cursor: pointer;
	}

	h3 {
		margin-bottom: 1em;
	}

	h4 {
		margin-bottom: 0.75em;
	}

	ul {
		margin-left: 1em;
	}

	@media (min-width: 1250px) and (max-width: 3000px) {
		.close {
			left: 2%;
		}
	}
}