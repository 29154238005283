.blog-container {
	position: relative;
	max-width: 80vw;
	min-width: 80vw;
	margin-left: 15%;
	padding: 1em 5px;
	min-height: 25vh;
	display: flex;
	justify-content: flex-start;
	overflow-x: hidden;

	.indicator {
		position: absolute;
		bottom: 0;
		left: 0;
		display: flex;

		.indicator-dot {
			width: 10px;
			height: 10px;
			margin: 0 3px;
			background-color: rgba(0, 0, 0, 0.4);
			border-radius: 50%;
			transition: 250ms ease-in-out;
			cursor: pointer;
		}

		.indicator-dot-on {
			width: 14px;
			height: 14px;
			margin: 0 3px;
			background-color: #571f4e;
			border-radius: 50%;
			transition: 250ms ease-in-out;
			cursor: pointer;
		}
	}

	@media (min-width: 1250px) and (max-width: 3000px) {
		margin-left: 5.5%;
		margin-bottom: 1em;
	}
}

.blog {
	position: relative;
	max-width: 95%;
	min-width: 95%;
	border-radius: 1em;
	box-shadow: 0 0.1em 0.25em 0 #eee;
	padding: 1em;
	margin: 1em 2.5%;
	transition: 250ms ease-in-out;

	img {
		max-width: 90%;
		border-radius: 1em;
	}

	h2 {
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-image: linear-gradient(45deg, #aaa, #571f4e);
	}

	.story {
		position: relative;
		overflow-y: hidden;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		margin-bottom: 1em;

		p {
			&:first-letter {
				font-size: 1.5em;
				color: #571f4e;
			}
		}
	}

	button {
		position: absolute;
		bottom: 1em;
		right: 1em;
		font-size: 0.75em;
		border-radius: 1em;
		box-shadow: 0 0.1em 0.25em #eee;
		border: none;
		outline: none;
		padding: 0.5em 1em;
		background-color: #fff;
		cursor: pointer;
		color: #571f4e;
	}
}

.loader {
	margin: auto;
}