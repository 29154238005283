header {
	position: fixed;
	top: 0;
	width: 100vw;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.8);
	box-shadow: 0 0 0.25em 0 #eee;
	z-index: 999;
	.logo {
		padding: 1em 2.5%;
		font-size: 1.5em;
		font-weight: 600;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-image: linear-gradient(45deg, #000, #000, #000, #d1d);
		background-size: 200% auto;
		animation-name: logo;
		animation-duration: 6s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
	}
	.download {
		font-size: 1.5em;
		margin-right: 5%;
		color: #571f4e;
		cursor: pointer;
	}
}
@keyframes logo {
	50% {
		background-position: -200% center;
	}
	100% {
		background-position: -200% center;
	}
}