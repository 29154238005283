//--ignore-compile

.info {
	padding: 1em;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border-radius: 0 0 1em 1em;
	box-shadow: 0 0.1em 0.1em 0 #eee;
	background-color: #fff;
	cursor: pointer;
	color: #571f4e;
	transition: 500ms ease-in-out;

	&:hover {
		box-shadow: 0 0.2em 0.2em 0 #ddd;
	}

	p {
		margin-left: 1em;
		color: #571f4e;
	}

	.plus-icon {
		color: #000;
	}
}

.developer {
	.title {
		position: fixed;
		transform: rotate(90deg) translateX(-50%);
		font-size: 1.25em;
		left: -5%;
		top: 50vh;
		z-index: -1;

		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			background: #000;
			top: 50%;
			left: -100%;
		}

		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			background: #000;
			top: 50%;
			right: -100%;
		}

		@media (min-width: 500px) and (max-width: 1200px) {
			left: -1.5%;
		}

		@media (min-width: 1250px) and (max-width: 1500px) {
			left: -1%;
		}

		@media (min-width: 1250px) and (max-width: 3000px) {
			left: -0.5%;
		}
	}
}

.developer.open {
	pointer-events: none;
}

.dev-img-container {
	min-height: 25vh;

	.git-img {
		position: absolute;
		top: 10%;
		right: 5%;
		width: 200px;
		height: 400px;
		border-radius: 1em;
	}

	.code-img {
		position: absolute;
		top: 20%;
		right: 30%;
		width: 200px;
		height: 400px;
		border-radius: 1em;
		z-index: -1;
	}

	@media (min-width: 1250px) and (max-width: 1500px) {
		margin-top: 10%;

		.git-img {
			transform: scale(1.5);
		}

		.code-img {
			transform: scale(1.5);
			top: 70%;
		}
	}

	@media (min-width: 1250px) and (max-width: 3000px) {
		margin-top: 10%;

		.git-img {
			transform: scale(1.5);
			right: 15%;
			top: 30%;
		}

		.code-img {
			transform: scale(1.5);
			top: 45%;
			right: 35%;
		}
	}
}

.project-title {
	margin-top: 50vh;

	@media (min-width: 1250px) and (max-width: 3000px) {
		margin-top: 75vh;
	}
}

.project-container {
	width: 80%;
	margin-left: 15%;

	.project-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1em;
		justify-content: space-evenly;
		align-content: start;
		min-height: 70vh;

		.project {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			padding: 1em 0.75em;
			border-radius: 1em;
			box-shadow: 0 0.1em 0.25em 0 #eee;

			a {
				text-decoration: none;
				padding: 0.5em 0;
			}

			h2 {
				font-size: 0.75em;
				color: #571f4e;
			}

			p {
				font-size: 0.75em;
			}

			.code-link {
				padding: 2em 0 0 0;
				font-size: 0.25em;
				display: block;
				max-width: 50%;
				color: #571f4e;
				justify-self: flex-end;
			}

			.tags {
				position: absolute;
				top: 4%;
				right: 4%;
				color: #000;
				cursor: pointer;
			}
		}
	}

	@media (min-width: 1250px) and (max-width: 1500px) {
		margin-left: 5.5%;
		width: 94%;

		.project-grid {
			grid-template-columns: repeat(3, 1fr);
			min-height: 50vh;
		}
	}

	@media (min-width: 1250px) and (max-width: 3000px) {
		margin-left: 5%;
		width: 90%;

		.project-grid {
			grid-template-columns: repeat(3, 1fr);
			min-height: 50vh;
		}
	}
}

.paginate-btns {
	padding: 0.75em 0;
	margin-top: 1em;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;

	button {
		font-size: 0.75em;
		border-radius: 1em;
		box-shadow: 0 0.1em 0.25em #eee;
		border: none;
		outline: none;
		padding: 0.5em 1em;
		background-color: #fff;
		cursor: pointer;
		color: #571f4e;
	}

	button.no-touch {
		color: rgba(0, 0, 0, 0.4);
		pointer-events: none;
	}

	p {
		margin: 0 1.5em;
	}
}

.loader {
	margin: 0 auto;
}