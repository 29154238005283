.copyright {
	text-align: center;
	font-size: 0.25em;
}
.name {
	font-size: 1.5em;
	padding-bottom: 0.5em;
	text-align: center;
	background-image: linear-gradient(to right, #eee, #571f4e 90%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}