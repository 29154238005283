.blog-post-section {
	position: fixed;
	padding: 20% 5% 0 5%;
	top: 0;
	max-width: 100vw;
	height: 100vh;
	overflow-y: auto;
	z-index: 900;
	background-color: #fff;

	h1 {
		margin-bottom: 0.5em;
	}

	h2 {
		margin-bottom: 1em;
	}

	img {
		max-width: 100%;
		min-width: 100%;
		border-radius: 1em;
	}

	h3 {
		margin-top: 1.25em;
		font-size: 2em;
	}

	h4 {
		margin-bottom: 0.5em;
		margin-top: 1em;
	}

	ul {
		margin-left: 2em;
	}

	.story {
		&:first-letter {
			margin-left: 2em;
		}
	}

	.close {
		margin-bottom: 15vh;
		margin-top: 2em;
		border: none;
		outline: none;
		border-radius: 1em;
		padding: 0.25em 0.5em;
		box-shadow: 0 0.1em 0.25em #eee;
		cursor: pointer;
		color: #571f4e;
	}

	.author {
		margin-top: 1em;
	}
}